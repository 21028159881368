import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { observer } from 'mobx-react';

export const Header=observer(()=>{
    useEffect(() => {
    const doIt = async () => {
        console.log('Init header');
      };
      doIt();
    }, []);

  return (
    <div className='header'>
        SmartCellSoft
    </div>
  );
});

export default Header;
