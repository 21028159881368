
export interface PostItem
{
    cmd : number;
    data: any;
}

export interface HttpRequest<REQB> {
  path: string;
  method?: string;
  body?: REQB;
  accessToken?: string;
}

export interface HttpResponse<RESB> extends Response {
  parsedBody?: RESB;
}

export const http = <REQB, RESB>(
  config: HttpRequest<REQB>,
): Promise<HttpResponse<RESB>> => {
    return new Promise((resolve, reject) => {
      const request = new Request(`${config.path}`,config.method==='get'?{}: //${'https://t2.check-a.net'}
    {
      method: config.method || 'get',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/api',//:'*/*',
        // 'Connection':'keep-alive'
        //'Authorization': `Bearer ${store.token}`  // передача токена в заголовке
      },
      credentials: 'same-origin',
      body: config.body ? JSON.stringify(config.body) : undefined,//JSON.stringify({"v":1, "cmd":"1", "code":"", "access_token":"", "guid":"", "login":"", "salt1":"", "url":""}) //
    });
    if (config.accessToken) {
      request.headers.set('authorization', `bearer ${config.accessToken}`);
    }
//    console.log("http:"+request.body);
    let response: HttpResponse<RESB>;
    fetch(request)
      .then(res => {
        response = res;
        if (res.headers.get('Content-Type') || ''.indexOf('json') > 0) {
          return res.json();
        } else {
          resolve(response);
        }
      })
      .then(body => {
        if (response.ok) {
          response.parsedBody = body;
          resolve(response);
        } else {
//          if(response.status===401)
//          {
//            signalState.setToken(undefined);
//          }
          if(response.status===400)
            console.log("BAD400");
          if(response.status===500)
            console.log("BAD500");
          reject(response);
        }
      })
      .catch(err => {
        console.error(err);
        reject(err);
        //reject(null);
      });
  });
};

export default PostItem;
