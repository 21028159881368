import React from 'react';
import '../css/Loading.css';

export const Loading = () => {
  return (
    <div>
        <svg className="loader loadercwr" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="48" fill="transparent" stroke="#888888" strokeWidth="1" strokeDasharray="85 15" strokeDashoffset="0"></circle>
            <circle cx="50" cy="50" r="32" fill="transparent" stroke="#888888" strokeWidth="1" strokeDasharray="65 35" strokeDashoffset="0"></circle>
        </svg>
        <svg className="loader loaderccwr" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" fill="transparent" stroke="#888888" strokeWidth="1" strokeDasharray="35 15" strokeDashoffset="0"></circle>
        </svg>
    </div>
  );
}

export default Loading;