import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface AuthContextProps {
  names: string[]|undefined;
  token: string|undefined;
  setAuth: (token:string|undefined, names:string[]|undefined) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string|undefined>('');
  const [names, setNames] = useState<string[]|undefined>(undefined);
  useEffect(() => {}, [token,names]);

  const setAuth = (token:string|undefined,names:string[]|undefined) => {
    if(token!==undefined)
      setToken(token);
    if(names!==undefined)
      setNames(names);
  };

  return (
    <AuthContext.Provider value={{ names, token, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
